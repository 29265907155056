import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { db } from '../../firebase';
import { collection, query, getDocs } from '@firebase/firestore';
import { convertStateToAbbreviation, createDateString } from '../../utils';

import {
  TouranmentsContainer,
  TournamentsHeader,
  TournamentsContent,
  TournamentsFiltersWrapper,
  TournamentsFilters,
} from './TournamentsPageElements';
import TournamentListElement from './TournamentListElement';

function TournamentsPage() {
  
  const fetchData = async () => {
    const tournamentListLocal = []
    const q = query(collection(db, "tournaments"))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      const docData = doc.data()
      const tournamentObject = {
        tournamentType: docData.tournamentTypes[0],
        association: docData.associations[0],
        tournamentName: docData.tournamentName,
        golfCourse: docData.golfCourses[0],
        city: docData.cities[0],
        state: docData.states[0],
        startDate: docData.startDate,
        endDate: docData.endDate,
        id: doc.id
      }
      tournamentListLocal.push(tournamentObject)
    });
    setTournamentList(tournamentListLocal)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    fetchData();
  }, []);

  const [loading, setLoading] = useState(true)

  const [countryList, setCountryList] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [stateList, setStateList] = useState([])
  const [selectedStates, setSelectedStates] = useState([])
  const [tournamentTypeList, setTournamentTypeList] = useState([])
  const [selectedTournamentTypes, setSelectedTournamentTypes] = useState([])
  const [associationList, setAssociationList] = useState([])
  const [selectedAssociations, setSelectedAssociations] = useState([])

  const [tournamentList, setTournamentList] = useState([])

  const renderTournamentsList = () =>{
    const tournamentRenderList = []
    for (let i = 0; i < tournamentList.length; i++) {
      const tournamentEntry = tournamentList[i];
      const location = tournamentEntry.city + ", " + convertStateToAbbreviation(tournamentEntry.state);
      const dateString = createDateString(tournamentEntry.startDate, tournamentEntry.endDate);
      tournamentRenderList.push(
        <TournamentListElement 
            date={dateString}
            tournamentType={tournamentEntry.tournamentType}
            id={tournamentEntry.id}
            association={tournamentEntry.association}
            golfCourse={tournamentEntry.golfCourse}
            location={location}
            tournamentName={tournamentEntry.tournamentName}
            key={i}
          />
      )
    }
    return tournamentRenderList;
  }

  if (loading) {
    return (
      <>
        <h1>Loading...</h1>
      </>
    )
  }

  return (
    <TouranmentsContainer id='tournaments'>
      <TournamentsContent>
        <TournamentsHeader>
          Tournaments
        </TournamentsHeader>
        <h2 style={{ marginTop: '20px' }}>Filters</h2>
        <TournamentsFiltersWrapper>
          <TournamentsFilters>
            <div style={{width: '250px'}}>
              <h3>Country</h3>
              <Select isMulti={true} menuPlacement='auto' menuPosition='fixed' options={ [ {value: 'chocoloate', label: "Chocolate"}, {value: 'vanilla', label: "Vanilla"}, {value: 'strawberry', label: "Strawberry"} ] } />
            </div>
          </TournamentsFilters>
          <TournamentsFilters>
            <div style={{width: '250px'}}>
              <h3>State</h3>
              <Select isMulti={true} menuPlacement='auto' menuPosition='fixed' options={ [ {value: 'chocoloate', label: "Chocolate"}, {value: 'vanilla', label: "Vanilla"}, {value: 'strawberry', label: "Strawberry"} ] } />
            </div>
          </TournamentsFilters>
          <TournamentsFilters>
            <div style={{width: '250px'}}>
              <h3>Tournament Type</h3>
              <Select isMulti={true} menuPlacement='auto' menuPosition='fixed' options={ [ {value: 'chocoloate', label: "Chocolate"}, {value: 'vanilla', label: "Vanilla"}, {value: 'strawberry', label: "Strawberry"} ] } />
            </div>
          </TournamentsFilters>
          <TournamentsFilters>
            <div style={{width: '250px'}}>
              <h3>Association</h3>
              <Select isMulti={true} menuPlacement='auto' menuPosition='fixed' options={ [ {value: 'chocoloate', label: "Chocolate"}, {value: 'vanilla', label: "Vanilla"}, {value: 'strawberry', label: "Strawberry"} ] } />
            </div>
          </TournamentsFilters>
        </TournamentsFiltersWrapper>
        { renderTournamentsList() }
      </TournamentsContent>
    </TouranmentsContainer>
  );
}

export default TournamentsPage;