import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import { db } from '../../firebase';
import { collection, query, getDocs, addDoc } from '@firebase/firestore';
import { convertStateToAbbreviation, createDateString } from '../../utils';
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Switch from "react-switch";

import {
  TouranmentsContainer,
  TournamentsHeader,
  TournamentsContent,
  TournamentsFiltersWrapper,
  TournamentsFilters,
} from './TournamentsPageElements';
import TournamentListElement from './TournamentListElement';

//create your forceUpdate hook
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here 
    // is better than directly setting `setValue(value + 1)`
}

function TournamentsPage() {
  
  // Tournament category: (list of strings) [must match the values in categories table]
  // Tournament Name (string) [can be same as other tournaments, e.g. in the case of pre-qualifiers]
  // Tournament golf courses (list)
  //    - can be new or existing golf courses
  //    - for new, need to have: 
  //      - name (string)
  //      - city (string)
  //      - state (string)
  //      - country (string)
  //      - website (string)
  // Start date (date)
  // End date (date)
  // Open to public (boolean)
  // Other sites (list of references to other tournament objects)
  //    - also adds current tournament as other site for the added tournaments
  // Qualifier for (list of references to other tournament objects)
  //    - also adds current tournament to qualifiers list for added tournaments
  // Qualifiers (list of references to other tournament objects)
  //    - also adds current tournament as qualifier for added tournaments
  // Related tournaments (list of references to other tournaments objects)
  //    - also adds current tournament as related tournament for added tournaments
  // Tournament page link (string)
  // Tournament type: (list of strings) [must match the values in tournamentTypes table]
  // Tournament associations (list of strings)
  //    - can be new or existing association
  //    - for new, need to have:
  //      - associationType (string) [must match one of the values in associationTypes table]
  //      - name (string)

  const forceUpdate = useForceUpdate();

  const createTournament = async () => {
    setLoading(true)
    console.log("Tournament name:", tournamentName)
    console.log("Golf Courses:", golfCourses)
    console.log("New Golf Courses:", newCourses)
    console.log("Start Date:", startDate)
    console.log("End Date:", endDate)
    console.log("Open to Public:", openToPublic)
    console.log("Other sites:", otherSites)
    console.log("Qualifier For:", qualifierFor)
    console.log("Qualifiers:", qualifiers)
    console.log("Related Tournaments:", relatedTournaments)
    console.log("Tournament Website:", tournamentPage)
    console.log("Tournament Type:", tournamentTypes)
    console.log("Tournament Associations:", associations)
    console.log("New Associations:", newAssociations)

    // first, handle associations
    const allAssociations = []
    associations.forEach((association) => {
        allAssociations.push(association.value)
    })
    if (newAssociations.length !== 0) {
        // if new associations, add them to DB
        console.log("creating new associations:", newAssociations)
        for (var i = 0; i < newAssociations.length; i++) {
            const docRef = await addDoc(collection(db, "associations"), {
                name: newAssociations[i].name,
                associationType: newAssociations[i].type
            });
            console.log("associations document written with ID: ", docRef.id);
            allAssociations.push(newAssociations[i].name)
        }
    }

    // next, create new courses
    const allGolfCourses = []
    const allCities = []
    const allStates = []
    const allCountries = []
    golfCourses.forEach((golfCourse) => {
        allGolfCourses.push(golfCourse.name)
        allCities.push(golfCourse.city)
        allStates.push(golfCourse.state)
        allCountries.push(golfCourse.country)
    })
    if (newCourses.length !== 0) {
        // if new courses, add them to DB
        console.log("creating new courses:", newCourses)
        for (var i = 0; i < newCourses.length; i++) {
            const docRef = await addDoc(collection(db, "golfCourses"), {
                city: newCourses[i].city,
                country: newCourses[i].country,
                name: newCourses[i].name,
                state: newCourses[i].state,
                website: newCourses[i].website
            })
            console.log("golfCourses document written with ID: ", docRef.id);
            allGolfCourses.push(newCourses[i].name)
            allCities.push(newCourses[i].city)
            allStates.push(newCourses[i].state)
            allCountries.push(newCourses[i].country)
        }
    }

    // pull just tournament type name
    const tournamentTypesLocal = []
    tournamentTypes.forEach((tournamentType) => {
        tournamentTypesLocal.push(tournamentType.value)
    })

    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const startDateMonth = month[startDate.getMonth()];
    const startDateDay = startDate.getDate()
    const startDateYear = startDate.getFullYear()
    const startDateString = `${startDateMonth} ${startDateDay}, ${startDateYear}`
    const endDateMonth = month[endDate.getMonth()];
    const endDateDay = endDate.getDate()
    const endDateYear = endDate.getFullYear()
    const endDateString = `${endDateMonth} ${endDateDay}, ${endDateYear}`

    // finaly, create new tournament document

    const docRef = await addDoc(collection(db, "tournaments"), {
        associations: allAssociations,
        cities: allCities,
        countries: allCountries,
        endDate: endDateString,
        golfCourses: allGolfCourses,
        openToPublic: openToPublic,
        otherSites: otherSites,
        qualifierFor: qualifierFor,
        qualifierNeeded: qualifierNeeded,
        qualifiers: qualifiers,
        relatedTournaments: relatedTournaments,
        states: allStates,
        startDate: startDateString,
        tournamentName: tournamentName,
        tournamentPage: tournamentPage,
        tournamentTypes: tournamentTypesLocal
    })
    console.log("tournaments document written with ID: ", docRef.id);
    resetForm()
    setLoading(false)
  }

  const resetForm = () => {
    setAllGolfCourses([])
    setAllTournamentTypes([])
    setAllAssociations([])
    setAllTournaments([])
    setAllAssociationTypes([])
    setTournamentName("")
    setGolfCourses([])
    setOpenToPublic(true)
    setQualifierNeeded(false)
    setOtherSites([])
    setQualifierFor([])
    setQualifiers([])
    setRelatedTournaments([])
    setTournamentPage("")
    setTournamentTypes([])
    setAssociations([])
    startDateOnChange(new Date())
    endDateOnChange(new Date())
    setCreateNewCourse(false)
    createNewAssociation(false)
    setNewCourseButtonText("Create New Course(s)")
    setNewAssociationButtonText("Create New Association(s)")
    setNewAssociations([])
    setNewCourses([])
  }

  const fetchData = async () => {
    const tournamentListLocal = []
    const tournamentDocs = await getDocs(collection(db, "tournaments"))
    tournamentDocs.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const docData = doc.data()
      const tournamentDisplayName = `${docData.tournamentName} @ ${docData.golfCourses[0]}`
      const tournamentObject = {
        label: tournamentDisplayName,
        value: docData.tournamentName,
        id: doc.id
      }
      tournamentListLocal.push(tournamentObject)
    });
    setAllTournaments(tournamentListLocal)

    const categoriesLocal = []
    const categoriesDocs = await getDocs(collection(db, "categories"))
    categoriesDocs.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const docData = doc.data()
      const categoryObject = {
        label: docData.category,
        value: docData.category
      }
      categoriesLocal.push(categoryObject)
    });
    setAllCategories(categoriesLocal)

    const golfCourseListLocal = []
    const golfCourseDocs = await getDocs(collection(db, "golfCourses"))
    golfCourseDocs.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const docData = doc.data()
      const courseDisplayName = `${docData.name} - ${docData.city}, ${docData.state}`
      const golfCourseObject = {
        label: courseDisplayName,
        value: docData.name,
        name: docData.name,
        city: docData.city,
        state: docData.state,
        country: docData.country,
        id: doc.id
      }
      golfCourseListLocal.push(golfCourseObject)
    });
    setAllGolfCourses(golfCourseListLocal)

    const tournamentTypeListLocal = []
    const tournamentTypeDocs = await getDocs(collection(db, "tournamentTypes"))
    tournamentTypeDocs.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const docData = doc.data()
      const golfCourseObject = {
        label: docData.type,
        value: docData.type,
        id: doc.id
      }
      tournamentTypeListLocal.push(golfCourseObject)
    });
    setAllTournamentTypes(tournamentTypeListLocal)

    const associationsListLocal = []
    const associationDocs = await getDocs(collection(db, "associations"))
    associationDocs.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const docData = doc.data()
      const associationInfoName = `${docData.name} - ${docData.associationType}`
      const associationObject = {
        label: associationInfoName,
        value: docData.name,
        id: doc.id
      }
      associationsListLocal.push(associationObject)
    });
    setAllAssociations(associationsListLocal)

    const associationTypeListLocal = []
    const associationTypeDocs = await getDocs(collection(db, "associationTypes"))
    associationTypeDocs.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const docData = doc.data()
      const associationTypeObject = {
        label: docData.type,
        value: docData.type,
        id: doc.id
      }
      associationTypeListLocal.push(associationTypeObject)
    });
    setAllAssociationTypes(associationTypeListLocal)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    fetchData();
  }, []);

  const [loading, setLoading] = useState(true)

  const [allCategories, setAllCategories] = useState([])
  const [allGolfCourses, setAllGolfCourses] = useState([])
  const [allTournamentTypes, setAllTournamentTypes] = useState([])
  const [allAssociations, setAllAssociations] = useState([])
  const [allTournaments, setAllTournaments] = useState([])
  const [allAssociationTypes, setAllAssociationTypes] = useState([])
  
  const [categories, setCategories] = useState([])
  const [tournamentName, setTournamentName] = useState("")
  const [golfCourses, setGolfCourses] = useState([])
  const [openToPublic, setOpenToPublic] = useState(true)
  const [qualifierNeeded, setQualifierNeeded] = useState(false)
  const [otherSites, setOtherSites] = useState([])
  const [qualifierFor, setQualifierFor] = useState([])
  const [qualifiers, setQualifiers] = useState([])
  const [relatedTournaments, setRelatedTournaments] = useState([])
  const [tournamentPage, setTournamentPage] = useState("")
  const [tournamentTypes, setTournamentTypes] = useState([])
  const [associations, setAssociations] = useState([])
  const [startDate, startDateOnChange] = useState(new Date())
  const [endDate, endDateOnChange] = useState(new Date())
  
  const [createNewCourse, setCreateNewCourse] = useState(false)
  const [createNewAssociation, setCreateNewAssociation] = useState(false)
  const [newCourseButtonText, setNewCourseButtonText] = useState("Create New Course(s)")
  const [newAssociationButtonText, setNewAssociationButtonText] = useState("Create New Association(s)")

  const [newAssociations, setNewAssociations] = useState([])
  const [newCourses, setNewCourses] = useState([])

  const [yourName, setYourName] = useState("")
  const [yourEmail, setYourEmail] = useState("")
  const [yourPhoneNumber, setYourPhoneNumber] = useState("")

  const handleOpenToPublicChange = (checked) => {
    setOpenToPublic(checked)
  }

  const handleQualifierNeededChange = (checked) => {
    setQualifierNeeded(checked)
  }

  const removeNewAssociation = (event) => {
    const newAssociationsLocal = newAssociations
    newAssociationsLocal.pop()
    setNewAssociations(newAssociationsLocal)
    forceUpdate()
  }

  const removeNewCourse = (event) => {
    const newCoursesLocal = newCourses
    newCoursesLocal.pop()
    setNewCourses(newCoursesLocal)
    forceUpdate()
  }

  const setNewCourseCity = (event, number) => {
    const newCoursesLocal = newCourses
    const city = event.target.value
    newCoursesLocal[number].city = city
    setNewCourses(newCoursesLocal)
  }

  const setNewCourseState = (event, number) => {
    const newCoursesLocal = newCourses
    const state = event.target.value
    newCoursesLocal[number].state = state
    setNewCourses(newCoursesLocal)
  }

  const setNewCourseCountry = (event, number) => {
    const newCoursesLocal = newCourses
    const country = event.target.value
    newCoursesLocal[number].country = country
    setNewCourses(newCoursesLocal)
  }

  const setNewCourseWebsite = (event, number) => {
    const newCoursesLocal = newCourses
    const website = event.target.value
    newCoursesLocal[number].website = website
    setNewCourses(newCoursesLocal)
  }

  const setNewCourseName = (event, number) => {
    const newCoursesLocal = newCourses
    const name = event.target.value
    newCoursesLocal[number].name = name
    setNewCourses(newCoursesLocal)
  }

  const setNewAssociationName = (event, number) => {
    const newAssociationsLocal = newAssociations
    const name = event.target.value
    newAssociationsLocal[number].name = name
    setNewAssociations(newAssociationsLocal)
  }

  const setNewAssociationType = (event, number) => {
    const newAssociationsLocal = newAssociations
    const type = event.value
    newAssociationsLocal[number].type = type
    setNewAssociations(newAssociationsLocal)
  }

  const addAssociationToList = () => {
    const newAssociationsLocal = newAssociations
    newAssociationsLocal.push({name: "", type: ""})
    setNewAssociations(newAssociationsLocal)
    forceUpdate()
  }

  const addCourseToList = () => {
    const newCoursesLocal = newCourses
    newCoursesLocal.push({name: "", type: ""})
    setNewCourses(newCoursesLocal)
    forceUpdate()
  }

  const renderAddAssociations = () => {
    const newAssociationsLocal = newAssociations
    return (
        <>
        {newAssociationsLocal.map((data, i) => {
            return (
                <div key={i}>
                    <div style={{ marginTop: "20px", align: "left", marginLeft: "40px" }}>
                        <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                            Association Name
                        </div>
                        <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                            <input onChange={event => setNewAssociationName(event, i)} />
                        </div>
                    </div>
                    <div style={{ marginTop: "20px", align: "left", marginLeft: "40px" }}>
                        <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                            Association Type
                        </div>
                        <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                            <Select isMulti={false} menuPlacement='auto' menuPosition='fixed' options={allAssociationTypes} onChange={(event) => setNewAssociationType(event, i)} />
                        </div>
                    </div>
                </div>
            )
        })}
        {
            newAssociationsLocal.length > 1 ?
            (
                <div style={{ align: 'right', width: "250px", marginLeft: "500px" }}>
                    <button type="button" onClick={(event) => removeNewAssociation(event)}>
                        Remove
                    </button>
                </div>
            )
            :
            (
                <></>
            )
        }
        </>
    )
  }

  const addAssociation = () => {
    if (createNewAssociation) {
      return (
        <>
            {renderAddAssociations()}
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <button type="button" onClick={addAssociationToList}>
                Add Another Association
              </button>
            </div>
        </>
      )
    }
  }

  const newAssociationButtonOnClick = () => {
    if (createNewAssociation) {
        setCreateNewAssociation(false)
        setNewAssociationButtonText("Create New Association(s)")
        setNewAssociations([])
    }
    else {
        setCreateNewAssociation(true)
        setNewAssociationButtonText("Use Existing Association(s) only")
        addAssociationToList()
    }
  }

  const renderAddCourses = () => {
    const newCoursesLocal = newCourses
    return (
        <>
        {newCoursesLocal.map((data, i) => {
            return (
                <div key={i}>
                    <div style={{ marginTop: "20px", align: "left", marginLeft: "40px" }}>
                        <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                            Course Name
                        </div>
                        <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                            <input onChange={event => setNewCourseName(event, i)} />
                        </div>
                    </div>
                    <div style={{ marginTop: "20px", align: "left", marginLeft: "40px" }}>
                        <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                            City
                        </div>
                        <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                            <input onChange={event => setNewCourseCity(event, i)} />
                        </div>
                    </div>
                    <div style={{ marginTop: "20px", align: "left", marginLeft: "40px" }}>
                        <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                            State
                        </div>
                        <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                            <input onChange={event => setNewCourseState(event, i)} />
                        </div>
                    </div>
                    <div style={{ marginTop: "20px", align: "left", marginLeft: "40px" }}>
                        <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                            Country
                        </div>
                        <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                            <input onChange={event => setNewCourseCountry(event, i)} />
                        </div>
                    </div>
                    <div style={{ marginTop: "20px", align: "left", marginLeft: "40px" }}>
                        <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                            Website
                        </div>
                        <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                            <input onChange={event => setNewCourseWebsite(event, i)} />
                        </div>
                    </div>
                </div>
            )
        })}
        {
            newCoursesLocal.length > 1 ?
            (
                <div style={{ align: 'right', width: "250px", marginLeft: "500px" }}>
                    <button type="button" onClick={(event) => removeNewCourse(event)}>
                        Remove
                    </button>
                </div>
            )
            :
            (
                <></>
            )
        }
        </>
    )
  }

  const addCourse = () => {
    if (createNewCourse) {
        return (
          <>
              {renderAddCourses()}
              <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                <button type="button" onClick={addCourseToList}>
                  Add Another Course
                </button>
              </div>
          </>
        )
      }
  }

  const newCourseButtonOnClick = () => {
    if (createNewCourse) {
        setCreateNewCourse(false)
        setNewCourseButtonText("Create New Course(s)")
        setNewCourses([])
    }
    else {
        setCreateNewCourse(true)
        setNewCourseButtonText("Use Existing Course(s) only")
        addCourseToList()
    }
  }

  if (loading) {
    return (
      <>
        <h1>Loading...</h1>
      </>
    )
  }

  return (
    <TouranmentsContainer id='tournaments'>
      <TournamentsContent>
        <TournamentsHeader>
          Add Tournament
        </TournamentsHeader>
        {/* Tournament name */}
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Your Name
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                <input value={yourName} onChange={e => setYourName(e.target.value)} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Your Email
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                <input value={yourEmail} onChange={e => setYourEmail(e.target.value)} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Your Phone Number
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                <input value={yourPhoneNumber} onChange={e => setYourPhoneNumber(e.target.value)} />
            </div>
        </div>
        <hr></hr>
        <hr></hr>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Tournament Category
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Select isDisabled={false} isMulti={true} menuPlacement='auto' menuPosition='fixed' options={allCategories} onChange={setCategories} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Tournament Name
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
                <input value={tournamentName} onChange={e => setTournamentName(e.target.value)} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Host Course(s)
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Select isDisabled={false} isMulti={true} menuPlacement='auto' menuPosition='fixed' options={allGolfCourses} onChange={setGolfCourses} />
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <button type="button" onClick={newCourseButtonOnClick}>
                {newCourseButtonText}
              </button>
            </div>
        </div>
        {addCourse()}
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Start Date
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <DatePicker onChange={startDateOnChange} value={startDate} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                End Date
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <DatePicker onChange={endDateOnChange} value={endDate} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Open to Public?
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Switch onChange={handleOpenToPublicChange} checked={openToPublic} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Qualifier Needed?
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Switch onChange={handleQualifierNeededChange} checked={qualifierNeeded} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Other Sites
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Select isMulti={true} menuPlacement='auto' menuPosition='fixed' options={allTournaments} onChange={setOtherSites} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Qualifier For
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Select isMulti={true} menuPlacement='auto' menuPosition='fixed' options={allTournaments} onChange={setQualifierFor} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Qualifiers
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Select isMulti={true} menuPlacement='auto' menuPosition='fixed' options={allTournaments} onChange={setQualifiers} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Related Tournaments
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Select isMulti={true} menuPlacement='auto' menuPosition='fixed' options={allTournaments} onChange={setRelatedTournaments} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Tournament Page
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <input value={tournamentPage} onChange={e => setTournamentPage(e.target.value)} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Tournament Type
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Select isMulti={true} menuPlacement='auto' menuPosition='fixed' options={allTournamentTypes} onChange={setTournamentTypes} />
            </div>
        </div>
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '250px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                Tournament Associations
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <Select isDisabled={false} isMulti={true} menuPlacement='auto' menuPosition='fixed' options={allAssociations} onChange={setAssociations} />
            </div>
            <div style={{ display: 'table-cell', align: 'left', width: "250px" }}>
              <button type="button" onClick={newAssociationButtonOnClick}>
                {newAssociationButtonText}
              </button>
            </div>
        </div>
        {addAssociation()}
        <div style={{ marginTop: "20px", align: "left", marginBottom: "80px" }}>
            <button type="button" onClick={createTournament}>
              Create Tournament
            </button>
        </div>
      </TournamentsContent>
    </TouranmentsContainer>
  );
}

export default TournamentsPage;