import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarExternalLink,
  SidebarRoute,
  SidebarExternalRoute,
  SideBtnWrap
} from './SidebarElements';

const Sidebar = ({ isopen, toggle }) => {
  return (
    <SidebarContainer
      style= {{
        opacity: isopen ? '100%' : '0',
        top: isopen ? '0' : '-100%'
      }}
      onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to='/'
            onClick={toggle}
            smooth='true'
            duration={500}
            spy='true'
            exact='true'
            offset={-80}
          >
            Home
          </SidebarLink>
          <SidebarLink
            to='/tournaments'
            onClick={toggle}
            smooth='true'
            duration={500}
            spy='true'
            exact='true'
            offset={-80}
          >
            Tournaments
          </SidebarLink>
          <SidebarLink
            to='/locations'
            onClick={toggle}
            smooth='true'
            duration={500}
            spy='true'
            exact='true'
            offset={-80}
          >
            Associations
          </SidebarLink>
          <SidebarLink
            to='/news'
            onClick={toggle}
            smooth='true'
            duration={500}
            spy='true'
            exact='true'
            offset={-80}
          >
            News
          </SidebarLink>
        </SidebarMenu>
        {/* <SideBtnWrap>
          <SidebarExternalRoute href='https://app.tumbledelivery.com/'>Login</SidebarExternalRoute>
        </SideBtnWrap> */}
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;