import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import AddTournamentsPage from '../components/AddTournamentsPage';
// import { Helmet } from "react-helmet";
// TODO: reimplement react helmet, use example in Tumble Delivery Website

function AddTournaments() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
        <Sidebar isopen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} darkMode={true} />
        <AddTournamentsPage />
        <Footer />
    </>
  );
}

export default AddTournaments;