export const homeObjOne = {
    id: 'tournaments',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Tournaments',
    headline: 'Every Pro Tournament, All in One Place',
    description:
      "Tired of spending hours searching for tournaments?  Now you can find them all in one place.  Find tournaments by state, association, and tournament type.",
    buttonLabel: 'Find Tourneys',
    imgStart: false,
    img: require('../../images/jt_rory.jpg'),
    alt: 'tourneys',
    dark: false,
    primary: true,
    darkText: true,
    routeButton: true,
    buttonDestination: '/tournaments'
  };
  
  export const homeObjTwo = {
    id: 'associations',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Associations',
    headline: 'All the associations that run pro tournaments',
    description:
      'Learn more about these associations, the tournaments they run, and how to join.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/golf_associations.png'),
    alt: 'associations',
    dark: false,
    primary: true,
    darkText: true,
    routeButton: true,
    buttonDestination: '/associations'
  };
  
  export const homeObjThree = {
    id: 'news',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'News',
    headline: 'News from around the pro golf world',
    description:
      'See results and updates for tournaments, as well as stories and player profiles you won\'t find anywhere else.',
    buttonLabel: 'Read More',
    imgStart: false,
    img: require('../../images/golf_tournament_leaderboard.jpg'),
    alt: 'news',
    dark: false,
    primary: true,
    darkText: true,
    routeButton: true,
    buttonDestination: '/news'
  };