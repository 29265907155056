// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore"
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDJJHZJMefSjhvF8nIQzylu3cwtBeZ7we8",
    authDomain: "pro-golf-tournaments.firebaseapp.com",
    projectId: "pro-golf-tournaments",
    storageBucket: "pro-golf-tournaments.appspot.com",
    messagingSenderId: "658536982884",
    appId: "1:658536982884:web:7097a6c1a6bd428fa03271",
    measurementId: "G-JDVKXPPJ6Y"
};

// This constant indicates if application is in development or production.
// If true, it will use the local firebase emulators for auth (and potentially firestore and functions)
const AUTH_DEV = true;
const FUNCTIONS_DEV = true;
const FIRESTORE_DEV = false;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
if (FIRESTORE_DEV) {
  connectFirestoreEmulator(db, "http://127.0.0.1:8080")
}
export const analytics = getAnalytics(app);
