const convertMonthNumberToAbbreviatedName = (monthNumber) => {
    switch(monthNumber) {
        case 0:
            return "Jan"
        case 1:
            return "Feb"
        case 2:
            return "Mar"
        case 3:
            return "Apr"
        case 4:
            return "May"
        case 5:
            return "Jun"
        case 6:
            return "Jul"
        case 7:
            return "Aug"
        case 8:
            return "Sep"
        case 9:
            return "Oct"
        case 10:
            return "Nov"
        case 11:
            return "Dec"
        default:
            return "This ain't a month"
    }
}

export const convertStateToAbbreviation = (stateName) => {
    switch(stateName) {
        case "California":
            return "CA"
        case "Texas":
            return "TX"
        case "Nevada":
            return "NV"
        case "Oregon":
            return "OR"
        case "Washington":
            return "WA"
        case "Alabama":
            return "AL"
        case "Delaware":
            return "DE"
        case "Rhode Island":
            return "RI"
        case "Colorado":
            return "CO"
        case "Connecticut":
            return "CT"
        case "Alaska":
            return "AK"
        case "Arkansas":
            return "AR"
        case "Arizona":
            return "AZ"
        case "Florida":
            return "FL"
        case "Hawaii":
            return "HI"
        case "Idaho":
            return "ID"
        case "Kansas":
            return "KS"
        case "Louisiana":
            return "LA"
        case "Maine":
            return "ME"
        case "Nebraska":
            return "NE"
        case "Ohio":
            return "OH"
        case "Pennsylvania":
            return "PA"
        case "South Carolina":
            return "SC"
        case "Vermont":
            return "VT"
        case "Tennessee":
            return "TN"
        case "Utah":
            return "UT"
        case "Michigan":
            return "MI"
        case "Indiana":
            return "IN"
        case "Iowa":
            return "IA"
        case "Illinois":
            return "IL"
        case "Kentucky":
            return "KY"
        case "Maryland":
            return "MD"
        case "Minnesota":
            return "MN"
        case "Montana":
            return "MT"
        case "New Hampshire":
            return "NH"
        case "New Jersey":
            return "NJ"
        case "New Mexico":
            return "NM"
        case "North Carolina":
            return "NC"
        case "North Dakota":
            return "ND"
        case "Massachusetts":
            return "MA"
        case "Oklahoma":
            return "OK"
        case "South Dakota":
            return "SD"
        case "West Virginia":
            return "WV"
        case "Wyoming":
            return "WY"
        case "Virginia":
            return "VA"
        case "Wisconsin":
            return "WI"
        case "Missouri":
            return "MO"
        case "Mississippi":
            return "MS"
        case "Georgia":
            return "GA"
        case "New York":
            return "NY"
        default:
            return "This ain't a state"
    }
}

export const createDateString = (startDateString, endDateString) => {
    const startDate = new Date(startDateString)
    const endDate = new Date(endDateString)
    if (startDate.getFullYear() == endDate.getFullYear()) {
        // same year
        if (startDate.getMonth() == endDate.getMonth()) {
            // same month
            if (startDate.getDate() == endDate.getDate()) {
                // one day
                return convertMonthNumberToAbbreviatedName(startDate.getMonth()) + " " + startDate.getDate()
            }
            else {
                // multiple days
                return convertMonthNumberToAbbreviatedName(startDate.getMonth()) + " " + startDate.getDate() + "-" + endDate.getDate()
            }
        }
        else {
            // different month
            return convertMonthNumberToAbbreviatedName(startDate.getMonth()) + " " + startDate.getDate() + "-" + convertMonthNumberToAbbreviatedName(endDate.getMonth()) + " " + endDate.getDate()
        }
    }
    else {
        // different year
        return startDateString + " - " + endDateString;
    }
    console.log("BEAU:", startDate.getDate())
    return "hi"
}