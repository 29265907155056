import React, { useState } from 'react';
import { RouteButton, ScrollButton } from '../ButtonElements';

import GolfCourse from '../../images/yale_golf_course.jpg'
import {
  HeroContainer,
  HeroPicture,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home' style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${GolfCourse})` }}>
      <HeroContent>
        <HeroH1>All Things Pro Golf</HeroH1>
        <HeroP>
          Tournaments, News, and More
        </HeroP>
        <HeroBtnWrapper>
          <ScrollButton
            to='tournaments'
            smooth='true'
            duration={500}
            spy='true'
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Learn More {hover ? <ArrowForward /> : <ArrowRight />}
          </ScrollButton>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;