import React from 'react';

function TournamentListElement({ date, tournamentType, association, golfCourse, location, tournamentName, id }) {
    const detailLinkUrl = "/tournaments/detail?" + id
    return (
        <div style={{ marginTop: "20px", align: "left" }}>
            <div style={{ display: 'table-cell', align: 'left', width: '125px', verticalAlign: 'middle', height: 'auto', fontSize: "20px" }}>
                { date }
            </div>
            <div style={{ display: 'table-cell', align: 'left' }}>
            <div>
                { tournamentType } | { association }
            </div>
            <div style={{ fontSize: "25px", paddingTop: "5px", paddingBottom: "5px" }}>
                <a href={detailLinkUrl}>{ tournamentName }</a>
            </div>
            <div>
                { golfCourse } | { location }
            </div>
            </div>
        </div>
    );
}

export default TournamentListElement;