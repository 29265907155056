import styled from 'styled-components';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const TouranmentsContainer = styled.div`
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh'
  background: #0c0c0c;
  display: flex;
  height: 900px;
  width: 100%;
  background-position: 50% 50%;
  background-size: 1920px 1200px;
  position: relative;
  z-index: 1;
  }
`;

export const TournamentsContent = styled.div`
  z-index: 3;
  margin-top: 125px;
  margin-left: 25px;
`;

export const TournamentsHeader = styled.h1`
  color: #000
`

export const TournamentsFiltersWrapper = styled.div`
  margin-left: 25px;
  margin-top: 25px;
  align: left;
`;

export const TournamentsFilters = styled.div`
  display: inline-block;
  align: left;
  margin-right: 10px;
  margin-bottom: 10px;
`;

