import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages'
import Tournaments from './pages/tournaments'
import AddTournaments from './pages/addTournaments';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/tournaments' element={<Tournaments />} />
        <Route path='/addtournaments' element={<AddTournaments />} />
      </Routes>
    </Router>
  );
}

export default App;
